import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  onboardingNavCount: {},
  error: false,
  errorMessage: '',
  countSuccessResponse: '',
};
const onboardingNavCount = createSlice({
  name: 'onboardingNavCount',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getOnboardingCount(state, action) {
      state.isLoading = false;
      state.onboardingNavCount = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setCountResponse(state, action) {
      state.countSuccessResponse = action.payload;
    },
  },
});
export default onboardingNavCount.reducer;
export const { startLoading, getOnboardingCount, hasError, setCountResponse } = onboardingNavCount.actions;
