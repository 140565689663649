import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, ListItemText, MenuItem, Popover, Stack, Typography } from '@mui/material';
//
import { useTheme } from '@mui/material/styles';

import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Iconify from '../../Iconify';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style';
import { isExternalLink } from '..';

// ----------------------------------------------------------------------

NavItemRoot.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  isCollapse: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
  },
}));
export function NavItemRoot({ item, isCollapse, open = false, active, onOpen }) {
  const theme = useTheme();

  const [content, setContent] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const handleHover = (event, children) => {
    if (isCollapse) {
      setAnchorEl(event.currentTarget);
      setContent(children);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const { title, path, icon, info, children } = item;
  const openPopover = Boolean(anchorEl);
  const classes = useStyles();
  const renderContent = (
    <>
      <Box>
        {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
        <Stack sx={{ position: 'absolute', top: '6px', right: '0px' }}>
          {isCollapse && children && <ArrowIcon open={false} />}
        </Stack>

        <Typography sx={{ fontSize: '9px', textAlign: 'center', marginLeft: '-16px' }}>
          {isCollapse && title}
        </Typography>
      </Box>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={openPopover}
        disableRestoreFocus
        anchorEl={anchorEl}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            overflow: 'inherit',
            border: ' 0.5px solid rgb(145 158 171 / 20%)',
            boxShadow: 1,
            ml: 0.75,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onMouseEnter={() => {
          if (anchorEl) {
            setAnchorEl(anchorEl);
          }
        }}
        onMouseLeave={handlePopoverClose}
      >
        {content?.map((item, index) => (
          <Link
            key={index}
            underline="none"
            sx={{
              '&.active': {
                color: theme.palette.primary.main,
              },
              color: 'black',
            }}
            component={RouterLink}
            to={item.path}
            onClick={handlePopoverClose}
          >
            <MenuItem key={index}>{item.title}</MenuItem>
          </Link>
        ))}
      </Popover>

      <ListItemTextStyle disableTypography primary={title} isCollapse={isCollapse} />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItemStyle
        isCollapse={isCollapse}
        onClick={onOpen}
        activeRoot={active}
        onMouseOver={(event) => isCollapse && handleHover(event, children)}
        onMouseLeave={handlePopoverClose}
        aria-owns="mouse-over-popover"
        aria-haspopup="true"
      >
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener" isCollapse={isCollapse}>
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle
      component={RouterLink}
      to={path}
      activeRoot={active}
      onClick={() => {
        if (!isCollapse) {
          onOpen();
        }
      }}
      onMouseOver={handlePopoverClose}
      isCollapse={isCollapse}
    >
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

NavItemSub.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

export function NavItemSub({ item, open = false, active = false, onOpen }) {
  const { title, path, info, children } = item;

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText disableTypography primary={title} />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeSub={active} subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener" subItem>
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeSub={active} subItem>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

DotIcon.propTypes = {
  active: PropTypes.bool,
};

export function DotIcon({ active }) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

ArrowIcon.propTypes = {
  open: PropTypes.bool,
};

export function ArrowIcon({ open }) {
  return (
    <Iconify
      icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
