import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  negativeStockData: [],
};

const negativeStock = createSlice({
  name: 'negativeStock',
  initialState,
  reducers: {
    getnegativeStockData(state, action) {
      state.negativeStockData = action.payload;
    },
  },
});
export default negativeStock.reducer;
export const { getnegativeStockData } = negativeStock.actions;
