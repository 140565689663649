import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import productReducer from './slices/product';
import loginReducer from './slices/login';
import dashboardReducer from './slices/dashboard';
import vendorsReducer from './slices/vendors';
import customersReducer from './slices/customers';
import employeeReducer from './slices/employee';

import leadsReducer from './slices/leadslice';
import ordersReducer from './slices/orderList';
import changepasswordReducer from './slices/changepassword';
import uploadFileReducer from './slices/uploadFile';
import breadcrumbsReducer from './slices/breadcrumbs';
import inventoryReducer from './slices/inventory';
import packageManagementReducer from './slices/packageManagement';
import regionMasterReducer from './slices/regionMaster';
import unitMasterReducer from './slices/unitMaster';
import responseStatusReducer from './slices/responseStatus';
import targetReducer from './slices/target';
import productListReducer from './slices/productListSlice';
import featureApprovalReducer from './slices/featureApproval';
import designationReducer from './slices/designation';
import forgotPasswordProcess from './slices/forgotPasswordProcess';
import onboardingNavCount from './slices/onboardingSlice';
import discountSlice from './slices/discountSlice';
import routeSlice from './slices/routeSlice';
import invoiceSlice from './slices/invoiceSlice';
import signupProcess from './slices/signupProcess';
import meetingReducer from './slices/meeting';
import negativeStockReducer from './slices/negativeStockSlice';
import territoryUploadReducer from './slices/territorySlice';
import randomImageReducer from './slices/randomImageSlice';
import taxReducer from './slices/taxSlice';
import getCustomerDetilsReducer from './slices/customerdetails';
import getHolidaysDetilsReducer from './slices/notificationSetting';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  product: persistReducer(productPersistConfig, productReducer),
  login: loginReducer,
  dashboard: dashboardReducer,
  vendors: vendorsReducer,
  customers: customersReducer,
  employee: employeeReducer,
  leads: leadsReducer,
  orders: ordersReducer,
  changepassword: changepasswordReducer,
  responseStatus: responseStatusReducer,
  uploadFile: uploadFileReducer,
  breadcrumbs: breadcrumbsReducer,
  inventory: inventoryReducer,
  packageManagement: packageManagementReducer,
  regionMaster: regionMasterReducer,
  unitMaster: unitMasterReducer,
  target: targetReducer,
  productListSlice: productListReducer,
  featureApproval: featureApprovalReducer,
  designation: designationReducer,
  forgotPasswordProcess,
  onboardingNavCount,
  discountSlice,
  routeSlice,
  invoiceSlice,
  signupProcess,
  meeting: meetingReducer,
  customerdetils: getCustomerDetilsReducer,
  negativeStock: negativeStockReducer,
  territoryUpload: territoryUploadReducer,
  randomImage: randomImageReducer,
  tax: taxReducer,
  notificationSetting: getHolidaysDetilsReducer,
});

export { rootPersistConfig, rootReducer };
