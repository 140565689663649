import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { PATH_AFTER_LOGIN } from '../configs/config';
import { useSelector } from '../redux/store';

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const isAuthenticated = window.localStorage.getItem('auth-token');
  const { user } = useSelector((state) => state.login);

  if (isAuthenticated && user?.config?.flag === 0) {
    return <Navigate to={'/onboarding/1'} />;
  }
  if (isAuthenticated) {
    return <Navigate to={PATH_AFTER_LOGIN} replace />;
  }

  return <>{children}</>;
}
