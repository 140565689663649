import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import {
  addNewInvoicePrefix,
  getInvoicePrefixSaga,
  getAllInvoiceListSaga,
  deleteInvoiceSaga,
  addNewInvoiceSaga,
  editInvoiceSaga,
  getSidebarContentSaga,
} from './invoiceSagaActions';

export function* invoiceSagaActions() {
  yield takeLatest(sagaActions.ADD_INVOICE_PREFIX, addNewInvoicePrefix);
  yield takeLatest(sagaActions.GET_INVOICE_PREFIX, getInvoicePrefixSaga);
  yield takeLatest(sagaActions.GET_ALL_INVOICE_LIST, getAllInvoiceListSaga);
  yield takeLatest(sagaActions.ADD_NEW_INVOICE, addNewInvoiceSaga);
  yield takeLatest(sagaActions.EDIT_INVOICE, editInvoiceSaga);
  yield takeLatest(sagaActions.DELETE_INVOICE, deleteInvoiceSaga);
  yield takeLatest(sagaActions.GET_SIDEBAR_CONTENT, getSidebarContentSaga);
}
