import { put, call } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import {
  startFormLoading,
  stopFormLoading,
  hasError,
  getErrorMessage,
  getSuccessMessage,
  setResponse,
} from '../../slices/responseStatus';
import { isLogout } from '../../slices/login';
import { getFeatureData } from '../../slices/featureApproval';

export function* featureApprovalSaga() {
  try {
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/featureApproval`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const approvalData = response?.data?.data?.approval;
    yield put(getFeatureData(approvalData === undefined ? [] : approvalData));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* setFeatureApprovalSaga(state) {
  try {
    yield put(hasError(false));
    const { data } = state;
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/featureApproval`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }
    yield call(featureApprovalSaga);
    yield put(stopFormLoading());
  } catch (error) {
    yield put(stopFormLoading());
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}
