import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import {
  unitListSaga,
  addNewUnitSaga,
  deleteUnitSaga,
  updateUnitSaga,
  getUnitHierarchy,
  updateUnitHierarchy,
} from './unitMasterSaga';

export function* unitMasterSaga() {
  yield takeLatest(sagaActions.GET_UNITLIST, unitListSaga);
  yield takeLatest(sagaActions.ADD_UNIT, addNewUnitSaga);
  yield takeLatest(sagaActions.DELETE_UNIT, deleteUnitSaga);
  yield takeLatest(sagaActions.EDIT_UNIT, updateUnitSaga);
  yield takeLatest(sagaActions.GET_UNIT_HIERARCHY, getUnitHierarchy);
  yield takeLatest(sagaActions.UPDATE_UNIT_HIERARCHY, updateUnitHierarchy);
}
