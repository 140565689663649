import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import { packageListSaga, deletePackage, addNewPackages, statusChangeofPackage, EditPackages } from './packageListSaga';

export function* packageManagementSaga() {
  yield takeLatest(sagaActions.GET_PACKAGELIST, packageListSaga);
  yield takeLatest(sagaActions.DELETE_PACKAGE, deletePackage);
  yield takeLatest(sagaActions.ADD_PACKAGELIST, addNewPackages);
  yield takeLatest(sagaActions.STATUSCHANGE_OF_PACKAGE, statusChangeofPackage);
  yield takeLatest(sagaActions.EDIT_PACKAGE, EditPackages);
}
