import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  routeData: [],
};

const routeSlice = createSlice({
  name: 'routeSlice',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    getAllRoutes(state, action) {
      state.isLoading = false;
      state.routeData = action.payload;
    },
    deleteRoute(state, action) {
      state.routeData = state.routeData.filter((data) => data.id !== action.payload);
    },
    addNewRoute(state, action) {
      state.routeData = [action.payload, ...state.routeData];
    },
    setAssignedEmployee(state, action) {
      state.routeData = state.routeData.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            config: {
              ...item.config,
              assigned_employee: action.payload.data.assigned_employee,
            },
          };
        }
        return item;
      });
    },
    editRoute(state, action) {
      state.routeData = state.routeData.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...action.payload.data,
            id: action.payload.id,
          };
        }
        return item;
      });
    },
  },
});
export default routeSlice.reducer;
export const { getAllRoutes, startLoading, deleteRoute, addNewRoute, setAssignedEmployee, editRoute } =
  routeSlice.actions;
