import { put, call } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import {
  startFormLoading,
  stopFormLoading,
  hasError,
  getErrorMessage,
  getSuccessMessage,
  setResponse,
} from '../../slices/responseStatus';
import { isLogout } from '../../slices/login';
import { getRandomImageData } from '../../slices/randomImageSlice';

export function* randomImageSaga() {
  try {
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/randomImage`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const randomImage = response?.data?.data?.random_image;
    yield put(getRandomImageData(randomImage === undefined ? [] : randomImage));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* setRandomImageSaga(state) {
  try {
    yield put(hasError(false));
    const { data } = state;
    const newData = {
      random_image: data,
    };
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/randomImage/toggle`, newData, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }
    yield call(randomImageSaga);
    yield put(stopFormLoading());
  } catch (error) {
    yield put(stopFormLoading());
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}
