import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  customerdetils: [],
};
const customerdetils = createSlice({
  name: 'customerdetils',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    getCustomerDetils(state, action) {
      state.isLoading = false;
      state.customerdetils = action.payload;
      state.error = false;
    },
  },
});

export default customerdetils.reducer;
export const { startLoading, hasError, getCustomerDetils } = customerdetils.actions;
