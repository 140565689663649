import { put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { startLoading, getMeeting } from '../../slices/meeting';
import {
  hasError,
  startFormLoading,
  stopFormLoading,
  getErrorMessage,
} from '../../slices/responseStatus';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';

export function* meetingListSaga(state) {
  try {
    const { startDate, endDate } = state;
    yield put(hasError(false));
    yield put(startLoading(true));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/meeting/list?startdate=${startDate}&enddate=${endDate}`, {
      headers: {
        'x-auth-token': Token,
      },
    });
    const meetingData = response?.data?.data?.metting || [];
    yield put(getMeeting(meetingData));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startLoading(false));
    yield put(stopFormLoading());
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
