import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  randomImageData: [],
};

const randomImage = createSlice({
  name: 'randomImage',
  initialState,
  reducers: {
    getRandomImageData(state, action) {
      state.randomImageData = action.payload;
    },
  },
});
export default randomImage.reducer;
export const { getRandomImageData } = randomImage.actions;
