import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  stocks: [],
  ditributor: [],
  productsStatistics: [],
  bottomProductsStatistics: [],
  buyersStatistics: [],
  bottomBuyersStatistics: [],
  employeesStatistics: [],
  bottomEmployeesStatistics: [],
  statisticsCount: undefined,
};
const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state) {
      state.isLoading = false;
      state.error = true;
    },
    getStokes(state, action) {
      state.isLoading = false;
      state.stocks = action.payload;
      state.error = false;
    },
    getDistributor(state, action) {
      state.isLoading = false;
      state.ditributor = action.payload;
    },
    getTopStatistics(state, action) {
      state.isLoading = false;
      state.productsStatistics = action.payload.productData;
      state.buyersStatistics = action.payload.buyerData;
      state.employeesStatistics = action.payload.employeeData;
    },
    getBottomStatistics(state, action) {
      state.isLoading = false;
      state.bottomProductsStatistics = action.payload.productData;
      state.bottomBuyersStatistics = action.payload.buyerData;
      state.bottomEmployeesStatistics = action.payload.employeeData;
    },
    getStatisticsCount(state, action) {
      state.isLoading = false;
      state.statisticsCount = action.payload;
    },
  },
});

export default dashboard.reducer;
export const {
  startLoading,
  hasError,
  getStokes,
  getDistributor,
  getTopStatistics,
  getStatisticsCount,
  getBottomStatistics,
} = dashboard.actions;
