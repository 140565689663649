import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../configs/config';
// components
import LoadingScreen from '../components/LoadingScreen';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },

        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify/:phoneNumber', element: <VerifyCode /> },
        { path: 'new-password/:phoneNumber', element: <NewPassword /> },
        { path: 'signup', element: <Register /> },
        // { path: 'signup/otp-verify', element: <OtpVerify /> },
        { path: 'thankyou', element: <Thankyou /> },
        { path: 'signup/confirm', element: <UpdateUserPage /> },
        { path: 'verifyEmail/:token', element: <VerifyEmailForSignUpPage /> },
      ],
    },
    // Dashboard Routes
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <GeneralDashboard /> },
        { path: 'analytics', element: <Analytics /> },
        { path: 'vendor', element: <GeneralVendors /> },

        { path: 'orders', element: <Orders /> },
        { path: 'leads', element: <Leads /> },
        { path: 'profile', element: <Profile /> },
        // { path: 'changepassword', element: <ChangePassword /> },

        {
          path: 'leads',
          children: [
            // { element: <Navigate to="/dashboard/leads" replace />, index: true },
            { path: 'pending', element: <Leads /> },
            { path: 'new', element: <Leads /> },
            { path: 'rejected', element: <Leads /> },
            { path: 'list', element: <Leads /> },
            { path: 'import', element: <LeadImportForm /> },
            { path: 'add', element: <AddNewLeadForm /> },
            { path: ':id/edit', element: <EditLeads /> },

            // { path: 'leadDetail', element: <ViewLeadDetailForm /> },
            // { path: ':id/inreviewleadsdetail', element: <InreviewLeadsDetail /> },
          ],
        },
        {
          path: 'orders',
          children: [
            { path: 'pending', element: <Orders /> },
            { path: 'confirm', element: <Orders /> },
            { path: 'reject', element: <Orders /> },
            { path: 'cancel', element: <Orders /> },
            { path: 'all', element: <Orders /> },
            { path: 'ViewOrderDetailsTable/:id', element: <ViewOrderDetailsTable /> },
          ],
        },
        {
          children: [
            {
              path: 'packageList',
              element: <PackageManagement />,
            },
          ],
        },
        {
          children: [
            {
              path: 'route',
              element: <RouteListTable />,
            },
          ],
        },
        {
          children: [{ path: 'discount', element: <DiscountTable /> }],
        },
        {
          children: [{ path: 'customerdetils', element: <CustomersDetils /> }],
        },
        {
          children: [{ path: 'farmerdetils', element: <FarmersDetils /> }],
        },
        {
          children: [{ path: 'dealerdetils', element: <DealerDetils /> }],
        },
        {
          path: 'vendors',
          children: [
            { path: 'customers', element: <CustomersDetils /> },
            { path: 'dealers', element: <DealerDetils /> },
          ],
        },
        {
          path: 'vendor',
          children: [
            { path: ':id/edit', element: <EditVendor /> },
            { path: 'add', element: <AddVendor /> },
            { path: 'import', element: <VendorImport /> },
          ],
        },
        {
          path: 'designation',
          children: [
            { path: 'list', element: <Designation /> },
            { path: 'hierarchy', element: <DesignationHirarchy /> },
            { path: 'designation-relation', element: <DesignationRelation /> },
            // { path: 'attendance', element: <EmployeeAttendance /> },
            // {
            //   path: 'employeelist',
            //   children: [
            //     { path: 'add', element: <AddEmployee /> },
            //     { path: ':id/edit', element: <EditEmployee /> },
            //     { path: 'import', element: <EmployeeImport /> },
            //     // { path: 'detail', element: <ViewEmployeeActivity /> },
            //   ],
            // },
          ],
        },

        {
          path: 'employee',
          children: [
            { path: 'active', element: <EmployeeList /> },
            { path: 'inactive', element: <EmployeeList /> },
            { path: 'deleted', element: <EmployeeList /> },
            { path: 'employeetarget', element: <EmployeeTarget /> },
            { path: 'attendance', element: <EmployeeAttendance /> },
            { path: 'registered', element: <RegisteredEmployee /> },
            { path: 'rejected', element: <RejectedEmployee /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            {
              path: ':year',
              element: <InvoiceTable />,
            },
          ],
        },
        {
          path: 'inventory',
          children: [
            { element: <Navigate to="/dashboard/inventory/category" replace />, index: true },
            { path: 'category', element: <ProductCategory /> },
            { path: 'products', element: <ProductList /> },
            { path: 'stock', element: <StockManagement /> },
            { path: 'stock/:id', element: <StockManagement /> },
            { path: 'price', element: <PriceList /> },

            {
              path: 'category',
              children: [
                { path: 'add', element: <AddCategory /> },
                { path: ':id/edit', element: <EditProductCategory /> },
              ],
            },
            {
              path: 'products',
              children: [
                // { path: ':id/edit', element: <EditProduct /> },
                { path: 'import', element: <ProductImport /> },
                // { path: 'add', element: <Addproduct /> },
              ],
            },
            {
              path: 'stock',
              children: [
                // { path: 'add', element: <AddStock /> },
                { path: 'import', element: <StockImport /> },
                // { path: ':id/edit', element: <AddStock /> },
              ],
            },
          ],
        },
        {
          children: [
            {
              path: 'meeting',
              element: <MeetingTable />,
            },
          ],
        },
        {
          path: 'profile',
          children: [
            { path: 'personal-information', element: <Profile /> },
            { path: 'company-detail', element: <Profile /> },
            { path: 'bank-detail', element: <Profile /> },
            { path: 'change-password', element: <Profile /> },
          ],
        },

        {
          path: 'unit',
          children: [
            { path: 'unitMaster', element: <UnitMaster /> },
            { path: 'Hierarchy', element: <UnitHierarchy /> },
          ],
        },
        {
          children: [
            {
              path: 'regionList',
              element: <RegionMaster />,
            },
          ],
        },
        {
          path: 'settings',
          children: [
            { path: 'featureApproval', element: <FeatureApproval /> },
            { path: 'invoiceNumber', element: <InvoiceNumberTable /> },
            { path: 'notification', element: <NotificationSetting /> },
            { path: 'negativeStock', element: <NegativeStock /> },
            { path: 'territoryUpload', element: <TerritoryUpload /> },
            { path: 'randomImage', element: <RandomImage /> },
            { path: 'tax', element: <Tax /> },
          ],
        },
        {
          children: [
            {
              path: 'UnauthorizedPage',
              element: <UnauthorizedPage />,
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: 'onboarding/:step', element: <OnboardingLayout /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/newPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
// const OtpVerify = Loadable(lazy(() => import('../pages/auth/OtpVerify')));
const Thankyou = Loadable(lazy(() => import('../pages/Thankyou')));
const UpdateUserPage = Loadable(lazy(() => import('../components/PleaseCheckEmailComponent')));
const VerifyEmailForSignUpPage = Loadable(lazy(() => import('../components/VerifyEmailForSignupPage')));
// Dashboard
const GeneralDashboard = Loadable(lazy(() => import('../pages/dashboard/GeneralDashboard')));
const Analytics = Loadable(lazy(() => import('../pages/dashboard/Analytics')));

const ProductCategory = Loadable(lazy(() => import('../sections/@dashboard/tables/ProductCategoryTable')));
const ProductList = Loadable(lazy(() => import('../sections/@dashboard/tables/ProductListTable')));
const StockManagement = Loadable(lazy(() => import('../pages/dashboard/StockManagement')));
const PriceList = Loadable(lazy(() => import('../sections/@dashboard/tables/PriceListTable')));
const GeneralVendors = Loadable(lazy(() => import('../sections/@dashboard/tables/VendorsListTable')));

const Leads = Loadable(lazy(() => import('../pages/dashboard/Leads')));
const LeadImportForm = Loadable(lazy(() => import('../sections/@dashboard/import/LeadImport')));
// const ViewLeadDetailForm = Loadable(lazy(() => import('../sections/@dashboard/forms/ViewLeadDetailForm')));
const Orders = Loadable(lazy(() => import('../pages/dashboard/Orders')));
const ViewOrderDetailsTable = Loadable(
  lazy(() => import('../sections/@dashboard/detail/orderdetail/ViewOrderDetailsTable'))
);
const Designation = Loadable(lazy(() => import('../sections/@dashboard/tables/DesignationListTable')));
const DesignationHirarchy = Loadable(lazy(() => import('../sections/@dashboard/tables/DeignationHierarchyTable')));
const DesignationRelation = Loadable(lazy(() => import('../sections/@dashboard/tables/DesignationRelation')));
const EmployeeTarget = Loadable(lazy(() => import('../sections/@dashboard/tables/EmployeeTargetTable')));
const EmployeeList = Loadable(lazy(() => import('../sections/@dashboard/tables/EmployeeListTable')));
const EmployeeAttendance = Loadable(lazy(() => import('../sections/@dashboard/tables/EmployeeAttendanceReportTable')));
const RegisteredEmployee = Loadable(lazy(() => import('../sections/@dashboard/tables/RegisteredEmployeeListTable')));
const RejectedEmployee = Loadable(lazy(() => import('../sections/@dashboard/tables/RejectedEmployeeListTable')));
const PackageManagement = Loadable(lazy(() => import('../sections/@dashboard/tables/PackageListTable')));
const UnitMaster = Loadable(lazy(() => import('../sections/@dashboard/tables/UnitMasterTable')));
const CustomersDetils = Loadable(lazy(() => import('../sections/@dashboard/tables/CustomerTable')));
const FarmersDetils = Loadable(lazy(() => import('../sections/@dashboard/tables/FarmerTable')));
const DealerDetils = Loadable(lazy(() => import('../sections/@dashboard/tables/DealerTable')));

const RegionMaster = Loadable(lazy(() => import('../sections/@dashboard/tables/RegionListTable')));
const MeetingTable = Loadable(lazy(() => import('../sections/@dashboard/tables/MeetingTable')));
const UnitHierarchy = Loadable(lazy(() => import('../sections/@dashboard/tables/UnitHierarchyTable')));
const Profile = Loadable(lazy(() => import('../pages/dashboard/Profile')));
// const ChangeProfilePassword = Loadable(lazy(() => import('../pages/dashboard/ChangePassword')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// form section
// const ProfileForm = Loadable(lazy(() => import('../sections/@dashboard/forms/profile/ProfileForm')));
const AddNewLeadForm = Loadable(lazy(() => import('../sections/@dashboard/forms/LeadForm')));
const EditLeads = Loadable(lazy(() => import('../sections/@dashboard/forms/LeadForm')));
const StockImport = Loadable(lazy(() => import('../sections/@dashboard/import/StockImport')));
const ProductImport = Loadable(lazy(() => import('../sections/@dashboard/import/ProductImport')));
// const EditProduct = Loadable(lazy(() => import('../sections/@dashboard/forms/ProductForm')));
// const AddStock = Loadable(lazy(() => import('../sections/@dashboard/forms/StockForm')));
const AddCategory = Loadable(lazy(() => import('../sections/@dashboard/forms/inventory/CategoryForm')));
const EditProductCategory = Loadable(lazy(() => import('../sections/@dashboard/forms/inventory/CategoryForm')));
// const Addproduct = Loadable(lazy(() => import('../sections/@dashboard/forms/ProductForm')));
// const InreviewLeadsDetail = Loadable(lazy(() => import('../sections/@dashboard/forms/InreviewLeadsDetail')));
const EditVendor = Loadable(lazy(() => import('../sections/@dashboard/forms/VendorForm')));
const AddVendor = Loadable(lazy(() => import('../sections/@dashboard/forms/VendorForm')));
// const EditEmployeeTarget = Loadable(lazy(() => import('../sections/@dashboard/forms/EmployeeTargetForm')));
// const AddEmployeeTarget = Loadable(lazy(() => import('../sections/@dashboard/forms/EmployeeTargetForm')));
// const EditEmployee = Loadable(lazy(() => import('../sections/@dashboard/forms/EmployeeListForm')));
// const AddEmployee = Loadable(lazy(() => import('../sections/@dashboard/forms/EmployeeListForm')));
const VendorImport = Loadable(lazy(() => import('../sections/@dashboard/import/VendorImport')));
// const EmployeeImport = Loadable(lazy(() => import('../sections/@dashboard/import/EmployeeImport')));
const FeatureApproval = Loadable(lazy(() => import('../pages/dashboard/FeatureApproval')));
const RouteListTable = Loadable(lazy(() => import('../sections/@dashboard/tables/RouteListTable')));
const DiscountTable = Loadable(lazy(() => import('../sections/@dashboard/tables/DiscountTable')));
const InvoiceTable = Loadable(lazy(() => import('../sections/@dashboard/tables/InvoiceListTable')));
const InvoiceNumberTable = Loadable(lazy(() => import('../pages/dashboard/InvoiceNumberUi')));
const NotificationSetting = Loadable(lazy(() => import('../sections/@dashboard/tables/NotificationSetting')));
const UnauthorizedPage = Loadable(lazy(() => import('../pages/UnauthorizedPage')));
const NegativeStock = Loadable(lazy(() => import('../pages/dashboard/NegativeStock')));
const TerritoryUpload = Loadable(lazy(() => import('../pages/dashboard/TerritoryUpload')));
const RandomImage = Loadable(lazy(() => import('../pages/dashboard/RandomImage')));
const Tax = Loadable(lazy(() => import('../pages/dashboard/TaxSwitch')));

// const ViewEmployeeActivity = Loadable(lazy(() => import('../sections/@dashboard/forms/ViewEmployeeActivity')));

// --------------------------------- onboarding_Flow ----------------------------

const OnboardingLayout = Loadable(lazy(() => import('../layouts/onboarding/index')));
