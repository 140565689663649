import { put, call } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { addNewUnit, deleteUnitSuccess, editUnitSuccess, getUnitMaster, startLoading } from '../../slices/unitMaster';
import {
  setResponse,
  hasError,
  startFormLoading,
  getErrorMessage,
  getDeleteMessage,
  getSuccessMessage,
  stopFormLoading,
} from '../../slices/responseStatus';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';

export function* unitListSaga() {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/units`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const unitData = response?.data?.data?.units?.unit;
    yield put(getUnitMaster(unitData === undefined ? [] : unitData));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* addNewUnitSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { data } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/units`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      const addNewUnitData = {
        id: response.data.data.id,
        ...data,
      };
      yield put(addNewUnit(addNewUnitData));
      yield put(getSuccessMessage(response.data.meta.message));
    }
    yield put(setResponse(response.status));
    // yield call(unitListSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* deleteUnitSaga(state) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const { id } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/units/${id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    // yield call(unitListSaga);
    if (response.status === 200) {
      yield put(deleteUnitSuccess(id));
    }
    yield put(startLoading(false));
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(hasError(true));
    yield put(startLoading(false));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* updateUnitSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { id } = state;
    const data = {
      unit: state.editedData.unit,
    };
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/units/${id}`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    // yield call(unitListSaga);
    if (response.status === 200) {
      yield put(editUnitSuccess({ data, id }));
      yield put(getSuccessMessage(response.data.meta.message));
    }
    yield put(setResponse(response.status));
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* getUnitHierarchy() {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const Token = window.localStorage.getItem('auth-token');
    yield axios.get(`${BASEURL}/unitHierarchy/units`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    // yield put(getUnitHierarchy(units));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* updateUnitHierarchy(state) {
  try {
    yield put(hasError(false));
    yield put(startLoading(true));
    const { items } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/units/hierarchy`, items, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield call(unitListSaga);
    if (response.status === 200) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
