import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  unitMaster: [],
  unitHierarchy: [],
};
const unitMaster = createSlice({
  name: 'unitMaster',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    hasError(state) {
      state.isLoading = false;
      state.error = true;
    },
    getUnitMaster(state, action) {
      state.isLoading = false;
      state.unitMaster = action.payload;
      state.error = false;
    },
    deleteUnitSuccess(state, action) {
      // state.isLoading = false;
      state.unitMaster = state.unitMaster.filter((data) => data?.id !== action.payload);
    },
    addNewUnit(state, action) {
      state.unitMaster = [action.payload, ...state.unitMaster];
    },
    editUnitSuccess(state, action) {
      state.isLoading = false;
      state.unitMaster = state.unitMaster.map((obj) => {
        if (obj?.id === action.payload.id) {
          return {
            ...obj,
            unit: action.payload.data.unit,
            id: action.payload.id,
          };
        }
        return obj;
      });
    },
    getUnitHierarchy(state, action) {
      state.isLoading = false;
      state.unitHierarchy = action.payload;
      state.error = false;
    },
  },
});

export default unitMaster.reducer;
export const {
  startLoading,
  hasError,
  getUnitMaster,
  deleteUnitSuccess,
  addNewUnit,
  editUnitSuccess,
  getUnitHierarchy,
} = unitMaster.actions;
