import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import { getAllDiscountData, addNewDiscount, deleteDiscountSaga, editDiscount } from './discountActionsSaga';

export function* discountSaga() {
  yield takeLatest(sagaActions.GET_ALL_DISCOUNT_DATA, getAllDiscountData);
  yield takeLatest(sagaActions.ADD_NEW_DISCOUNT, addNewDiscount);
  yield takeLatest(sagaActions.DELETE_DISCOUNT, deleteDiscountSaga);
  yield takeLatest(sagaActions.EDIT_DISCOUNT, editDiscount);
}
