import { put, call } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';
import {
  hasError,
  setResponse,
  startFormLoading,
  getErrorMessage,
  getDeleteMessage,
  getSuccessMessage,
} from '../../slices/responseStatus';
import { getAllDiscount, startLoading, deleteDiscount } from '../../slices/discountSlice';

export function* getAllDiscountData() {
  try {
    yield put(hasError(false));
    yield put(setResponse(''));
    yield put(startLoading(true));
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/discounts`, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    const discountsData = response.data.data.discounts;

    yield put(getAllDiscount(discountsData === undefined ? [] : discountsData));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* addNewDiscount(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item } = state;
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/discounts`, item, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
    yield call(getAllDiscountData);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* deleteDiscountSaga(state) {
  try {
    yield put(hasError(false));
    const { id } = state;
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/discounts/${id}`, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    if (response.status === 200) {
      yield put(deleteDiscount(id));
    }
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* editDiscount(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item, id } = state;
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/discounts/${id}`, item, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    if (response.status === 200) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }
    yield call(getAllDiscountData);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
