import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invalidCredential: null,
  isAuthenticated: false,
  isInitialized: false,
  isLoading: false,
  TokenInValid: '',
  IsTokenInvalid: false,
  error: false,
  user: null,
  status: null,
  ISlogout: false,
  IsLogoutFail: false,
  isLogoutSuccess: false,
  isLogoutMessage: '',
  profileNotUpdate: false,
  otpSendSuccess: '',
  otpSendSuccessMessage: '',
  showOtpSubmitField: '',
};

const login = createSlice({
  name: 'login',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    sendOtpSuccessReducer(state, action) {
      state.otpSendSuccess = action.payload;
    },
    sendOtpSuccessMessageReducer(state, action) {
      state.otpSendSuccessMessage = action.payload;
    },
    showOtpSubmitFieldReducer(state, action) {
      state.showOtpSubmitField = action.payload;
    },

    hasError(state) {
      state.isLoading = false;
      state.error = true;
    },
    clearError(state) {
      state.error = false;
    },

    isInitialized(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.isLoading = false;
    },

    isLoding(state) {
      state.isInitialized = true;
    },

    isLogin(state) {
      // state.isAuthenticated = true;
      state.isInitialized = false;
      state.invalidCredential = null;
      // state.isLoading = false;
      state.ISlogout = false;
      state.error = false;
      // state.user = action.payload;
      state.IstokenInvalis = false;
      state.ISlogout = false;
      state.isLogoutSuccess = false;
      state.TokenInValid = '';
      state.isLogoutMessage = '';
    },

    isError(state, action) {
      state.invalidCredential = action.payload;
      state.isLoading = false;
    },
    isInvalidToken(state, action) {
      if (action.payload === 'Invalid token') {
        state.error = true;
      }
    },
    isLogout(state, action) {
      state.isAuthenticated = false;
      state.isInitialized = false;
      state.error = false;
      state.user = null;
      state.isLoading = false;
      state.invalidCredential = action.payload;
      state.ISlogout = true;
      state.IsLogoutFail = false;
      state.isLogoutMessage = 'Log Out Successufuly';
    },
    isLogoutFail(state, action) {
      state.IsLogoutFail = action.payload;
      state.isLogoutMessage = 'Unable to Logout';
    },
    isLogoutSuccess(state, action) {
      state.isLogoutSuccess = true;
      state.isLogoutMessage = action.payload;
    },
    isLogoutSuccessClearAction(state) {
      state.isLogoutSuccess = false;
    },
    isProfileNotUpdate(state, action) {
      state.profileNotUpdate = action.payload;
      if (action.payload) {
        state.isLoading = false;
      }
    },
  },
});

export default login.reducer;
export const {
  isLogin,
  isLogout,
  isLoding,
  startLoading,
  hasError,
  isInitialized,
  isError,
  isInvalidToken,
  isLogoutFail,
  isLogoutSuccess,
  isProfileNotUpdate,
  isLogoutSuccessClearAction,
  stopLoading,
  sendOtpSuccessReducer,
  sendOtpSuccessMessageReducer,
  showOtpSubmitFieldReducer,
  clearError,
} = login.actions;
