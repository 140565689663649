import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';
import useManageSidebar from '../../../hooks/useManageSidebar';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters { ...props } />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavSectionVertical({ isCollapse = false, ...other }) {
  const { collectSidebarContentAsPerRole } = useManageSidebar();
  const valueWithDyanamicSidebar = [{ items: collectSidebarContentAsPerRole }];
  return (
    <Box { ...other }>
      { valueWithDyanamicSidebar.map((group, index) => (
        <List key={ index } disablePadding sx={ { px: 2 } }>
          { group.items.map((list) => (
            <NavListRoot key={ list.title } list={ list } isCollapse={ isCollapse } />
          )) }
        </List>
      )) }
    </Box>
  );
}
