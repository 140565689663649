import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  discountData: [],
};

const discountSlice = createSlice({
  name: 'discountSlice',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    getAllDiscount(state, action) {
      state.isLoading = false;
      state.discountData = action.payload;
    },
    deleteDiscount(state, action) {
      state.discountData = state.discountData.filter((data) => data.id !== action.payload);
    },
  },
});
export default discountSlice.reducer;
export const { getAllDiscount, startLoading, deleteDiscount } = discountSlice.actions;
