import { all, spawn, call } from 'redux-saga/effects';
import { loginSaga } from './loginSaga';
import { leadsSaga } from './leadsSaga';
import { dashboardSaga } from './dashboardSaga';
import { employeeSaga } from './employeeSaga';
import { vendorsSaga } from './vendorsSaga';
import { ordersSaga } from './orderListSaga';
import { customerSaga } from './customerSaga';
import { uploadSaga } from './uploadFileSaga';
import { inventorySaga } from './inventorySaga';
import { packageManagementSaga } from './packageManagementSaga';
import { regionMasterSaga } from './regionMasterSaga';
import { unitMasterSaga } from './unitMasterSaga';
import { featureSaga } from './featureApprovalSaga';
import { designationSaga } from './designationSaga.js';
import { forgotPasswordProcessSaga } from './forgotPassword';
import { onboardingNavIndexSaga } from './onboardingNavbarSaga';
import { discountSaga } from './discountSaga';
import { routeSaga } from './routeSaga';
import { invoiceSagaActions } from './invoiceSaga';
import { meetingSaga } from './meetingListSaga';
import { negativeSaga } from './negativeStockSaga';
import { territorySaga } from './TerritorySaga';
import { randomSaga } from './randomImageSaga';
import { taxSaga } from './taxSaga';
import { customerListSaga } from './customerDetilsSaga';
import { notificationSettingSaga } from './notificationSaga';

// eslint-disable-next-line
export default function* rootSaga() {
  const sagas = [
    loginSaga,
    dashboardSaga,
    designationSaga,
    vendorsSaga,
    customerSaga,
    employeeSaga,
    uploadSaga,
    leadsSaga,
    ordersSaga,
    inventorySaga,
    packageManagementSaga,
    regionMasterSaga,
    unitMasterSaga,
    featureSaga,
    forgotPasswordProcessSaga,
    onboardingNavIndexSaga,
    discountSaga,
    routeSaga,
    invoiceSagaActions,
    meetingSaga,
    negativeSaga,
    territorySaga,
    randomSaga,
    taxSaga,
    customerListSaga,
    notificationSettingSaga,
  ];
  yield all(
    sagas.map((saga) =>
      // eslint-disable-next-line
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.debug(e);
          }
        }
      })
    )
  );
}
