import { put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
// import { hasError } from '../../slices/responseStatus';
import { getOnboardingCount, startLoading, hasError, setCountResponse } from '../../slices/onboardingSlice';
import { GettingRoleID } from '../../../components/GettingRoleID';

export function* onboardingNavbarSaga(state) {
  try {
    yield put(hasError(false));
    const employeeId = GettingRoleID('employee');
    const vendorId = GettingRoleID('vendor');
    // const sendBody = {
    //   vendorRoleId: vendorId,
    //   employeeRoleId: employeeId,
    // };
    yield put(startLoading());
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/count?vendorRoleId=${vendorId}&employeeRoleId=${employeeId}`, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    const { sendSuccessResponse } = state;
    const countData = response.data.data;
    if (sendSuccessResponse) {
      if (response.status === 200) {
        yield put(setCountResponse(response.status));
      }
    }
    yield put(getOnboardingCount(countData));
  } catch (error) {
    yield put(hasError(true));
  }
}
