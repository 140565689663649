import { put, call } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { deletePackageSuccess, getPackageList, startLoading } from '../../slices/packageManagement';
import {
  setResponse,
  hasError,
  startFormLoading,
  getErrorMessage,
  getDeleteMessage,
  stopFormLoading,
  getSuccessMessage,
} from '../../slices/responseStatus';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';

export function* packageListSaga() {
  try {
    yield put(startFormLoading());
    yield put(startLoading(true));
    yield put(hasError(false));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/packages`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const packageData = response?.data?.data?.packages?.packageData;
    if (packageData === undefined) {
      yield put(getPackageList([]));
    } else {
      yield put(getPackageList(packageData));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(startLoading(false));

    yield put(getErrorMessage(error.error.message));
    if (error.error?.message === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* addNewPackages(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item } = state;

    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/packages`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    if (response.status === 200) {
      // const addNewPackage = {
      //   id: response.data.data.id,
      //   ...item,
      // };
      // console.log(addNewPackage, 'addNewPackage')
      // yield put(addNewPackageSuccess(addNewPackage));
      yield put(getSuccessMessage(response.data.meta.message));
    }
    yield put(setResponse(response.status));
    yield call(packageListSaga);
  } catch (error) {
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}

export function* EditPackages(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { item, id } = state;

    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/packages/${id}`, item, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    if (response.status === 200) {
      yield call(packageListSaga);
      yield put(getSuccessMessage(response.data.meta.message));
    }
    yield put(setResponse(response.status));
  } catch (error) {
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}
export function* deletePackage(data) {
  try {
    yield put(startLoading(true));
    yield put(hasError(false));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/packages/${data.id}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    // yield call(packageListSaga);
    if (response.status === 200) {
      yield put(deletePackageSuccess(data.id));
    }
    yield put(startLoading(false));
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(hasError(true));
    yield put(startLoading(false));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* statusChangeofPackage(state) {
  try {
    const { id, item, data } = state;
    const list = {
      status: data.config.status,
    };
    yield put(startLoading(true));
    yield put(hasError(false));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/packages/status/${id}`, list, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(getPackageList(item));
    }
    yield put(startLoading(false));
  } catch (error) {
    yield put(hasError(true));
    yield put(startLoading(false));
    yield put(getErrorMessage(error.error.message));
  }
}
