import { put, call } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';
import {
  hasError,
  setResponse,
  startFormLoading,
  getErrorMessage,
  getSuccessMessage,
  stopFormLoading,
  getDeleteMessage,
} from '../../slices/responseStatus';
import {
  getInvoicePatternString,
  startLoading,
  getAllInvoice,
  deleteInvoice,
  getSidebarContent,
  startSidebarLoader,
  getInvoicePrefixStatus,
} from '../../slices/invoiceSlice';

export function* getAllInvoiceListSaga() {
  try {
    const year = localStorage.getItem('financialYear');
    yield put(hasError(false));
    yield put(setResponse(''));
    yield put(startLoading(true));
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/invoices?year=${year}`, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    const invoiceList = response.data.data.invoices;

    yield put(getAllInvoice(invoiceList === undefined ? [] : invoiceList));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* addNewInvoiceSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { data } = state;
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/invoices`, data, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    if (response.status === 200) {
      data.id = response.data.data.invoice_added.id;
    }
    // yield put(addNewInvoiceLocally(data));
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
    yield call(getAllInvoiceListSaga);
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* addNewInvoicePrefix(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { data } = state;
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/invoices/invoice_number`, data, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* getInvoicePrefixSaga() {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/invoices/invoice_number`, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    yield put(getInvoicePrefixStatus(response.status));
    const invoiceData = response.data.data.invoice_no;

    yield put(getInvoicePatternString(invoiceData));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* deleteInvoiceSaga(state) {
  try {
    yield put(hasError(false));
    const { id } = state;
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/invoices/${id}`, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    if (response.status === 200) {
      yield put(deleteInvoice(id));
    }
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* editInvoiceSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { data, id } = state;
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/invoices/${id}`, data, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    if (response.status === 200) {
      // yield put(editInvoiceLocally({ data, id }));
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
      yield call(getAllInvoiceListSaga);
    }
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* getSidebarContentSaga() {
  try {
    yield put(hasError(false));
    yield put(startSidebarLoader(true));

    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/invoices/year`, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    const invoiceYears = response?.data?.data?.invoice_years;
    yield put(getSidebarContent(invoiceYears || []));
  } catch (error) {
    yield put(startSidebarLoader(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
