// i18n
// import './locales/i18n';

// // highlight
// import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// editor
import 'react-quill/dist/quill.snow.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './configs/reportWebVitals';

// ----------------------------------------------------------------------

ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();
