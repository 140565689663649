import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import {
  vendorsListSaga,
  addVendorSaga,
  DeleteVendorRowSaga,
  editVendorSaga,
  getdesignation,
  getRoles,
  gerVendorDetail,
  vendorBulkUpload,
  vendorHierarchy,
  assignunassignevendore,
  getTerritory,
} from './vendorsListSaga';

export function* vendorsSaga() {
  yield takeLatest(sagaActions.GET_VENDORS, vendorsListSaga);
  yield takeLatest(sagaActions.ADD_VENDORS, addVendorSaga);
  yield takeLatest(sagaActions.DELETE_VENDORS, DeleteVendorRowSaga);
  yield takeLatest(sagaActions.EDIT_VENDORS, editVendorSaga);
  yield takeLatest(sagaActions.GET_DESIGNATION, getdesignation);
  yield takeLatest(sagaActions.GET_ROLES, getRoles);
  yield takeLatest(sagaActions.GET_VENDORDETAIL, gerVendorDetail);
  yield takeLatest(sagaActions.VENDOR_BULKUPLOAD, vendorBulkUpload);
  yield takeLatest(sagaActions.VENDOR_HIERARCHY, vendorHierarchy);
  yield takeLatest(sagaActions.ASSIGNED_VENDOR, assignunassignevendore);
  yield takeLatest(sagaActions.GET_TERRITORY, getTerritory);
}
