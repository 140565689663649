import { call, put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { removeSession, setSession } from '../../../utils/jwt';
import {
  isLogin,
  isLogout,
  startLoading,
  hasError,
  isInitialized,
  isError,
  isInvalidToken,
  isLogoutFail,
  isLogoutSuccess,
  isProfileNotUpdate,
  stopLoading,
  sendOtpSuccessReducer,
  sendOtpSuccessMessageReducer,
  showOtpSubmitFieldReducer,
  clearError,
} from '../../slices/login';
import {
  getErrorMessage,
  getSuccessMessage,
  setResponse,
  hasError as customHasError,
} from '../../slices/responseStatus';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { getRoles } from '../vendorsSaga/vendorsListSaga';
import { regionListSaga } from '../regionMasterSaga/regionListSaga';

export function* sendOtpForLogin(state) {
  try {
    yield put(clearError());
    yield put(startLoading());
    const { data } = state;

    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/auth/sendOtp`, data, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });

    yield put(stopLoading());
    yield put(sendOtpSuccessMessageReducer(response.data.meta.message));
    yield put(sendOtpSuccessReducer(response.status));
    yield put(showOtpSubmitFieldReducer(response.status));
  } catch (error) {
    yield put(stopLoading());
    yield put(hasError());
    yield put(isError(error.error.message));
  }
}

export function* signinSaga(state) {
  try {
    const { data, remember } = state;

    yield put(startLoading());

    const response = yield axios.post(`${BASEURL}/auth/login`, data);

    if (remember) {
      const storeData = {
        number: data.username,
        remember,
      };
      localStorage.setItem('auth-user', JSON.stringify(storeData));
    } else {
      window.localStorage.removeItem('auth-user');
    }
    localStorage.setItem('auth-token', response.data.data.accessToken);
    const token = window.localStorage.getItem('auth-token');
    yield call(getProfileDetails);
    if (token !== null) {
      yield put(isLogin(response.config.data));
      yield put(getErrorMessage(''));
    }
  } catch (error) {
    yield put(hasError());
    yield put(isError(error.error.message));
  }
}

export function* getProfileDetails() {
  try {
    yield put(startLoading());
    // window.localStorage.removeItem('admin');
    const Token = window.localStorage.getItem('auth-token');
    const responses = yield axios.get(`${BASEURL}/profile`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    localStorage.setItem('admin', JSON.stringify(responses?.data.data.profile));
    if (responses.status === 200) {
      yield call(getRoles);
      yield call(regionListSaga);
    }
    yield put(isInitialized(responses.data.data.profile));
    // yield put(getErrorMessage(''));
  } catch (error) {
    yield put(hasError());
    yield put(isInvalidToken(error.error.message));
    window.localStorage.removeItem('admin');
    // yield put(startLoading());
  }
}

export function* updateProfile(state) {
  try {
    yield put(customHasError(false));
    yield put(setResponse(''));
    yield put(startLoading());
    const { index, item } = state;
    yield put(isProfileNotUpdate(false));

    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/users/${index}`, item, {
      headers: {
        'x-auth-token': `${Token}`,
        // 'Content-Type': 'multipart/form-data',
      },
    });
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));

    yield call(getProfileDetails);
  } catch (error) {
    yield put(customHasError(true));
    yield put(isProfileNotUpdate(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* logOut() {
  yield put(startLoading());
  try {
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/auth/logout`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });

    yield put(isLogout());
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('auth-token');
    window.localStorage.removeItem('admin');
    yield put(showOtpSubmitFieldReducer(''));
    setSession(null);
    removeSession();
    yield put(isLogoutSuccess(response.data.meta.message));
    window.localStorage.removeItem('roles');
    window.localStorage.removeItem('regionList');
  } catch (error) {
    yield put(isLogoutFail(true));
  }
}
