import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  response: '',
  packageManagement: [],
};
const packageManagement = createSlice({
  name: 'packageManagement',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    setResponse(state, action) {
      state.isLoading = false;
      state.response = action.payload;
    },
    hasError(state, action) {
      // state.isLoading = false;
      state.error = action.payload;
    },
    getPackageList(state, action) {
      state.isLoading = false;
      state.packageManagement = action.payload;
    },
    deletePackageSuccess(state, action) {
      state.isLoading = false;
      state.packageManagement = state.packageManagement.filter((data) => data.id !== action.payload);
    },
    addNewPackageSuccess(state, action) {
      state.packageManagement = [
        ...state.packageManagement,
        // {
        //   id: `e99f09a7-dd88-49d5-b1c8-1daf80c2d7b${state.packageManagement.length + 1}`,
        //   package_name: action.payload.package_name || '',
        //   packageQuantity: action.payload.packageQuantity || '',
        // },
        action.payload,
      ];
    },
    updateStatus(state, action) {
      state.isLoading = false;
      state.packageManagement = state.packageManagement.map((obj) => {
        if (obj.id === action.payload.id) {
          return {
            ...obj,
            id: action.payload.id,
            config: {
              status: action.payload.data.config.status,
            },
          };
        }
        return obj;
      });
    },
    updatePackageSuccess(state, action) {
      state.packageManagement = state.packageManagement.map((obj) => {
        if (obj.id === action.payload.id) {
          return {
            ...obj,
            id: action.payload.id,
            ...action.payload,
          };
        }
        return obj;
      });
    },
  },
});

export default packageManagement.reducer;
export const {
  startLoading,
  hasError,
  setResponse,
  getPackageList,
  deletePackageSuccess,
  addNewPackageSuccess,
  updateStatus,
  updatePackageSuccess,
} = packageManagement.actions;
