import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import { stockSaga } from './stockSaga';
import { distributerSaga } from './ditributerSaga';
import { bottomStatisticsSaga, dashboardTarget, topStatisticsSaga } from './dashboardtargetSaga';

export function* dashboardSaga() {
  yield takeLatest(sagaActions.GET_STOCKES, stockSaga);
  yield takeLatest(sagaActions.GET_DISTRIBUTERS, distributerSaga);
  yield takeLatest(sagaActions.GET_DASHBOARD_TARGET, dashboardTarget);
  yield takeLatest(sagaActions.GET_TOP_STATISTICS, topStatisticsSaga);
  yield takeLatest(sagaActions.GET_BOTTOM_STATISTICS, bottomStatisticsSaga);
  // yield takeLatest(sagaActions.GET_DASHBOARDEMPLOYEE_TARGET, employeeDashboardatarget);
}
