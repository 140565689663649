import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import { employeeAttendanceListSaga } from './employeeAttendanceSaga';
import {
  employeeListSaga,
  addEmployeeListSaga,
  deleteEmployeeRowSaga,
  editEmployeeListSaga,
  getEmployeeDesignation,
  employeeTargetSaga,
  employeeBulkUpload,
  assignEmployeeSaga,
  unassignEmployeeSaga,
  updateAssignUnassignSaga,
  childDesignationForEmployeeSaga,
  employeeTovendorHierarchy,
  assignunassignvendortoemployee,
  getVendorsByEmployeeDesignation,
  inactiveemployeeListSaga,
  employeeListSagaForView,
} from './employeeListSaga';
import {
  addEmployeeTargetSaga,
  deleteTargetSaga,
  employeeTargetListSaga,
  getConfigureTargetData,
  // employeeTargetById,
  updateEmployeeTarget,
} from './employeeTargetSaga';
import {
  assignDeletedUserSaga,
  getDeletedEmployeeByDesignation,
  getUsersByStatusSaga,
  rejectUserSaga,
} from './registeredEmployeeSaga';

export function* employeeSaga() {
  yield takeLatest(sagaActions.GET_EMPLOYEE_VIEW, employeeListSagaForView);
  yield takeLatest(sagaActions.GET_EMPLOYEE, employeeListSaga);
  yield takeLatest(sagaActions.GET_INACTIVE_EMPLOYEE, inactiveemployeeListSaga);

  yield takeLatest(sagaActions.GET_EMPLOYEE_DESIGNATION, getEmployeeDesignation);
  yield takeLatest(sagaActions.ADD_EMPLOYEE_LIST, addEmployeeListSaga);
  yield takeLatest(sagaActions.EDIT_EMPLOYEE_LIST, editEmployeeListSaga);
  yield takeLatest(sagaActions.DELETE_EMPLOYEE_LIST, deleteEmployeeRowSaga);
  yield takeLatest(sagaActions.GET_EMPLOYEE_DETAILS, employeeTargetSaga);
  yield takeLatest(sagaActions.GET_EMPLOYEE_ATTENDANCE, employeeAttendanceListSaga);
  // yield takeLatest(sagaActions.DOWNLOAD_ATTENDANCEREPORT, downloadAttendancetReportSaga);
  yield takeLatest(sagaActions.GET_ASSIGN_EMPLOYEE, assignEmployeeSaga);
  yield takeLatest(sagaActions.GET_UNASSIGN, unassignEmployeeSaga);
  yield takeLatest(sagaActions.UPDATE_ASSIGNMENT_OF_EMPLOYEE, updateAssignUnassignSaga);
  yield takeLatest(sagaActions.GET_CHILD_DESIGNATION, childDesignationForEmployeeSaga);
  yield takeLatest(sagaActions.GET_VENDORS_BY_EMPLOYEE_DESIGNATION, getVendorsByEmployeeDesignation);

  yield takeLatest(sagaActions.EMPLOYEE_BULKUPLOAD, employeeBulkUpload);
  yield takeLatest(sagaActions.EMPLOYEE_VENDOR_DESIGNATION, employeeTovendorHierarchy);
  yield takeLatest(sagaActions.ASSIGN_VENDORTOEMPLOYEE, assignunassignvendortoemployee);
  yield takeLatest(sagaActions.GET_EMPLOYEE_TARGET, employeeTargetListSaga);
  yield takeLatest(sagaActions.ADD_EMPLOYEE_TARGET, addEmployeeTargetSaga);
  // yield takeLatest(sagaActions.GET_EMPLOYEE_TARGET_BY_ID, employeeTargetById);
  yield takeLatest(sagaActions.UPDATE_EMPLOYEE_TARGET, updateEmployeeTarget);
  yield takeLatest(sagaActions.GET_CONFIGURE_TARGET, getConfigureTargetData);
  yield takeLatest(sagaActions.DELETE_EMPLOYEE_TARGET, deleteTargetSaga);
  yield takeLatest(sagaActions.GET_DELETED_EMPLOYEE_BY_DESIGNATION, getDeletedEmployeeByDesignation);
  yield takeLatest(sagaActions.GET_USERS_BY_STATUS, getUsersByStatusSaga);
  yield takeLatest(sagaActions.ASSIGN_DELETED_USER, assignDeletedUserSaga);
  yield takeLatest(sagaActions.REJECT_USER, rejectUserSaga);
}
