import { put } from 'redux-saga/effects';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import axios from '../../../utils/axios';
import { getErrorMessage, hasError } from '../../slices/responseStatus';
import { downloadAttendanceReport, getAttendanceList, startLoading } from '../../slices/employee';
import { isLogout } from '../../slices/login';

export function* employeeAttendanceListSaga(state) {
  try {
    const { startDate, endDate } = state;

    yield put(hasError(false));
    yield put(startLoading(true));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/attendance/report?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const { attendanceList } = response.data.data.Attendance;

    yield put(getAttendanceList(attendanceList === undefined ? [] : attendanceList));
    yield put(downloadAttendanceReport(attendanceList === undefined ? [] : attendanceList));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
// note :: this is for download report url logic that is converted in above url
// export function* downloadAttendancetReportSaga(state) {
//   try {
//     yield put(hasError(false));
//     // yield put(startLoading());
//     const { startDate, endDate } = state;
//     const Token = window.localStorage.getItem('auth-token');
//     const response = yield axios.get(
//       `${BASEURL}/userActivities/reportDownload?event_type=Start_Day&startDate=${startDate}&endDate=${endDate}`,
//       {
//         headers: {
//           'x-auth-token': `${Token}`,
//         },
//       }
//     );
//     const datas = response.data.data.UserActivities;
//     yield put(downloadAttendanceReport(datas === undefined ? [] : datas));
//   } catch (error) {
//     yield put(hasError(true));
//   }
// }
