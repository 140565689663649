import { takeLatest } from 'redux-saga/effects';
import sagaActions from '../../actions/index';
import { addNewRouteSaga, getAllRoutesSaga, deleteRouteSaga, editRouteSaga, assingEmployee } from './routeSaga';

export function* routeSaga() {
  yield takeLatest(sagaActions.ADD_ROUTE, addNewRouteSaga);
  yield takeLatest(sagaActions.GET_ALL_ROUTE, getAllRoutesSaga);
  yield takeLatest(sagaActions.DELETE_ROUTE, deleteRouteSaga);
  yield takeLatest(sagaActions.EDIT_ROUTE, editRouteSaga);
  yield takeLatest(sagaActions.ASSIGN_EMPLOYEE_ON_ROUTE, assingEmployee);
}
