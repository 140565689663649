import { put } from 'redux-saga/effects';
import { startTerritoryLoading } from '../../slices/territorySlice';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { hasError, getErrorMessage, getSuccessMessage, setResponse } from '../../slices/responseStatus';

export function* territoryUploadSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startTerritoryLoading());

    const { item } = state;
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/territorymaster/upload`, item, {
      headers: {
        'x-auth-token': `${Token}`,
        'content-type': 'multipart/form-data',
      },
    });
    if (response.status === 200) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
