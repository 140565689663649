import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  featureApprovalData: [],
};

const featureApproval = createSlice({
  name: 'featureApproval',
  initialState,
  reducers: {
    getFeatureData(state, action) {
      state.featureApprovalData = action.payload;
    },
  },
});
export default featureApproval.reducer;
export const { getFeatureData } = featureApproval.actions;
