// ----------------------------------------------------------------------

export default function Accordion() {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            boxShadow: 0,
            border: ' 0.5px solid rgb(145 158 171 / 20%)',
            borderRadius: 5,
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          // paddingLeft: theme.spacing(2),
          // paddingRight: theme.spacing(1),
          '&.Mui-disabled': {
            opacity: 1,
            // color: theme.palette.action.disabled,
            '& .MuiTypography-root': {
              color: 'inherit',
            },
          },
        },
        expandIconWrapper: {
          color: 'inherit',
        },
      },
    },
  };
}
