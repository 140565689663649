import { useSelector } from '../redux/store';
import { selectedRoles, modulesForOtherRoles } from '../roles/roleBasedModules';
import navConfig from '../layouts/dashboard/navbar/NavConfig';

const useManageSidebar = () => {
  const { sidebarContent } = useSelector((state) => state.invoiceSlice);
  const prepareArray = sidebarContent.map((item) => ({
    title: item,
    path: `/admin/invoice/${item}`,
  }));
  const modifiedContent = navConfig[0]?.items?.map((item) => {
    if (item.title === 'Invoice') {
      return {
        ...item,
        children: prepareArray,
      };
    }
    return item;
  });
  let collectSidebarContentAsPerRole;

  const getuser = JSON.parse(localStorage.getItem('admin') || '{}');
  if (selectedRoles.includes(getuser && getuser?.designation?.toUpperCase())) {
    collectSidebarContentAsPerRole = modifiedContent;
  } else {
    const filteredModules = modulesForOtherRoles.reduce((acc, item) => {
      const foundModule = modifiedContent.find((menu) => item.name === menu.title);
      if (foundModule) {
        acc.push(foundModule);
      }
      return acc;
    }, []);
    collectSidebarContentAsPerRole = filteredModules;
  }
  return { collectSidebarContentAsPerRole };
};
export default useManageSidebar;
