import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  vendors: [],
  designation: [],
  currentVendor: null,
  childvendor: null,
  unassignvendor: [],
  assigneduser: [],
  orderOfVendor: [],
  vendorCompetitorStockInfo: [],
  roles: [],
  OwnStockInfo: [],
  response: '',
  territories: [],
};
const vendors = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    startLoading(state, action) {
      state.isLoading = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    deleteVendor(state, action) {
      state.vendors = state.vendors.filter((data) => data.id !== action.payload);
    },
    getVendors(state, action) {
      state.vendors = action.payload;
      state.error = false;
      state.isLoading = false;
    },
    getVendorByid(state, action) {
      state.isLoading = false;
      state.currentVendor = action.payload;
    },
    getVendorOrder(state, action) {
      state.isLoading = false;
      state.orderOfVendor = action.payload;
    },
    getVendorCompetitorStock(state, action) {
      state.isLoading = false;
      state.vendorCompetitorStockInfo = action.payload;
    },
    getOwnStock(state, action) {
      state.isLoading = false;
      state.OwnStockInfo = action.payload;
    },

    vendorsBulkUpload(state, action) {
      state.isLoading = false;
      state.vendors = [...state.vendors, ...action.payload];
    },
    getdeigantionlist(state, action) {
      state.isLoading = false;
      state.designation = action.payload;
    },
    getRoleslist(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
    },
    setResponse(state, action) {
      state.isLoading = false;
      state.response = action.payload;
    },
    getChildvendore(state, action) {
      state.childvendor = action.payload;
    },
    getUnassignvendor(state, action) {
      state.unassignvendor = action.payload;
    },
    getAssigneduser(state, action) {
      state.assigneduser = action.payload;
    },
    getTerritoryList(state, action) {
      state.territories = action.payload;
    },
    addNewVendor(state, action) {
      state.vendors = [action.payload, ...state.vendors];
    },
    updateVendor(state, action) {
      state.vendors = state.vendors.map((obj) => [action.payload].find((o) => o.id === obj.id) || obj);
    },
  },
});

export default vendors.reducer;
export const {
  startLoading,
  hasError,
  getVendors,
  getVendorOrder,
  getVendorCompetitorStock,
  vendorsBulkUpload,
  getdeigantionlist,
  deleteVendor,
  getRoleslist,
  setResponse,
  getVendorByid,
  getChildvendore,
  getUnassignvendor,
  getAssigneduser,
  getTerritoryList,
  getOwnStock,
  addNewVendor,
  updateVendor,
} = vendors.actions;
