import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Drawer, Toolbar } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR, Image } from '../../../configs/config';
// components
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import CollapseButton from './CollapseButton';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const CollapseStyle = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '13px',
  lineHeight: '1.15',
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  // const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
  //   useCollapseDrawer();

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverLeave } = useCollapseDrawer();
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const CollapseExpand = () => (
    <Toolbar
      sx={{
        position: 'relative',
        left: '0',
        bottom: '0',
        minHeight: '42px !important',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
        {!isCollapse && <CollapseStyle>Collapse</CollapseStyle>}
      </Box>
    </Toolbar>
  );

  const renderContent = (
    <>
      <Box
        sx={{
          padding: '0px 6px 2px 6px',
          margin: isCollapse ? '8px 4px' : '16px 20px',
          display: 'flex',
          justifyContent: 'center',
          ...(isCollapse && { alignItems: 'center', pt: 0, px: 1, flexShrink: 0 }),
        }}
      >
        <img src={Image} alt="img" />
      </Box>

      <Scrollbar
        sx={{
          overflowX: 'hidden',
          height: 1,
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        }}
      >
        <NavSectionVertical isCollapse={isCollapse} />
      </Scrollbar>
      {isDesktop && <CollapseExpand />}
    </>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          // onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              bgcolor: 'rgb(248 248 248 / 1)',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
