import { put, call } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import {
  startFormLoading,
  stopFormLoading,
  hasError,
  getErrorMessage,
  getSuccessMessage,
  setResponse,
} from '../../slices/responseStatus';
import { isLogout } from '../../slices/login';
import { getTaxData } from '../../slices/taxSlice';

export function* getTaxSaga() {
  try {
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/tax`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const tax = response?.data?.data;
    yield put(getTaxData(tax === undefined ? [] : tax));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* setTaxSaga(state) {
  try {
    yield put(hasError(false));
    const { item } = state;
    const newData = {
      tax: item?.tax,
      gst: item?.gst,
    };
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/tax/update`, newData, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }
    yield call(getTaxSaga);
    yield put(stopFormLoading());
  } catch (error) {
    yield put(stopFormLoading());
    yield put(hasError(true));

    yield put(getErrorMessage(error.error.message));
  }
}
