import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  taxData: [],
};

const tax = createSlice({
  name: 'tax',
  initialState,
  reducers: {
    getTaxData(state, action) {
      state.taxData = action.payload;
    },
  },
});
export default tax.reducer;
export const { getTaxData } = tax.actions;
