import { put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';
import {
  hasError,
  setResponse,
  startFormLoading,
  getErrorMessage,
  getDeleteMessage,
  getSuccessMessage,
} from '../../slices/responseStatus';
import {
  getAllRoutes,
  startLoading,
  deleteRoute,
  addNewRoute,
  setAssignedEmployee,
  editRoute,
} from '../../slices/routeSlice';

export function* getAllRoutesSaga() {
  try {
    yield put(hasError(false));
    yield put(setResponse(''));
    yield put(startLoading(true));
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/routes`, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    const routesData = response?.data?.data?.Routes;

    yield put(getAllRoutes(routesData === undefined ? [] : routesData));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* addNewRouteSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { data } = state;
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/routes`, data, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    if (response.status === 200) {
      const addStatus = data.config.vendors.map((item) => ({
        ...item,
        status: 0,
      }));
      data.config.vendors = addStatus;
      data.id = response.data.data.id;
    }
    yield put(addNewRoute(data));
    yield put(getSuccessMessage(response.data.meta.message));
    yield put(setResponse(response.status));
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* deleteRouteSaga(state) {
  try {
    yield put(hasError(false));
    const { id } = state;
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.delete(`${BASEURL}/routes/${id}`, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    if (response.status === 200) {
      yield put(deleteRoute(id));
    }
    yield put(getDeleteMessage(response.data.meta.message));
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
export function* editRouteSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startFormLoading());
    const { data, id } = state;
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/routes/${id}`, data, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    if (response.status === 200) {
      yield put(editRoute({ data, id }));
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* assingEmployee(state) {
  try {
    const { data, id } = state;
    const token = window.localStorage.getItem('auth-token');
    const response = yield axios.put(`${BASEURL}/routes/assignEmployee/${id}`, data, {
      headers: {
        'x-auth-token': `${token}`,
      },
    });
    if (response.status === 200) {
      yield put(setAssignedEmployee({ data, id }));
    }
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
